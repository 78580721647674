import React, { useEffect, useState } from "react";
import firebase from "firebase";

const TotalVisitors = () => {
  const [totalVisitors, setTotalVisitors] = useState(0);

  useEffect(() => {
    const visitorsRef = firebase.database().ref("totalVisitors");

    // Increment the visitor count
    visitorsRef.transaction((currentValue) => {
      return (currentValue || 0) + 1;
    });

    // Listen for changes to the visitor count
    visitorsRef.on("value", (snapshot) => {
      setTotalVisitors(snapshot.val() || 0);
    });

    // Cleanup listener
    return () => {
      visitorsRef.off();
    };
  }, []);

  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <h2 className='text-4xl'>Total Visitors: {totalVisitors}</h2>
    </div>
  );
};

export default TotalVisitors;